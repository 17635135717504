/* eslint-disable react/jsx-key */
import React , {Component} from 'react';
import { ResponsiveTestimonialTab, 
  MenuListV2Main, CustomListItemV2Main, WrapperContainer, RatingsContainers, CoreFeatureSection,
  Divider, PaddingContainer, RatingWrapper, TabsContainerTestimonial} from '../WellnessPageV2/styles';
import { ImageUrl, imgPath } from '../../utils/constants';
import {ButtonLearnMoree,TabData, Button2, Section, IntroTextContainer, PosterImage,ResourcesWrapper,RowWrapper,MainResources,Resources,RowResponsiveWrapper} from './styles';
import PropTypes from "prop-types";
import data from './wellnessResources.json'
import {MenuLinks} from '../NavigationBarV2/styles';
import {connect} from 'react-redux';
import {subscribeNewsLetter} from '../../redux/actions/authActions';
import { ButtonGetStarted, WellBeing } from '../MainDashboardV3/styles';
import  testimonialData from './TestimonialData.json'
import LazyImage from '../common/LazyImage/LazyImage';
class RecognitionPage extends Component{

  constructor(){
    super();
    this.state = {
      mobileViewStatus: window.innerWidth <= 500,
      active:'',
      selectedImage:'',
      selectedText:'WHY USERS LOVE US',
      selectedTab: 0,
      wellnessResources: JSON.parse(JSON.stringify(data)),
      image: [data[0].data[0].image, data[1].data[0].image, data[2].data[0].image,data[3].data[0].image],
      email: '',
      tabArray: ["Insurance", "Banking", "Technology", "Healthcare", "Civil Engineering"],
      ratingImage: [testimonialData[0].imageURL, testimonialData[1].imageURL, testimonialData[2].imageURL, testimonialData[3].imageURL, testimonialData[4].imageURL],
      ratingData: [testimonialData[0].data, testimonialData[1].data, testimonialData[2].data, testimonialData[3].data, testimonialData[4].data],
      ratingName: [testimonialData[0].head, testimonialData[1].head, testimonialData[2].head, testimonialData[3].head, testimonialData[4].head],
      ratingMain: [testimonialData[0].main, testimonialData[1].main, testimonialData[2].main, testimonialData[3].main, testimonialData[4].main]
    }
  }

  componentDidMount(){
    this.setState({active:'Insurance'})
  }

  top =() => (
    <Section firstChild margin="0">
      <IntroTextContainer>
        <h1>
        Strengthen Workplace<br/>Communities
        </h1>
        <LazyImage div={"divider"} src={ImageUrl + "/images/RecognitionV2/Rectangle-yellow.png"}/>
        <p>
        Create a community where employees can connect {this.state.mobileViewStatus ? '' : <br/>}
        with peers across the organization that fosters {this.state.mobileViewStatus ? '' : <br/>}
        team diversity and those water-cooler moments{this.state.mobileViewStatus ? '' : <br/>}
        no matter the location.{this.state.mobileViewStatus ? '' : <br/>}
        </p>
        <div className="wrap">
          <ButtonLearnMoree onClick={()=> this.props.history.push('/contact')} noMargin={1}>REQUEST A DEMO</ButtonLearnMoree>
        </div>
      </IntroTextContainer>
      <PosterImage>
        <picture>
          <source srcSet="/public/images/CommunityV2/Community_banner_new.png" type="image/png"/>
          <img src="/public/images/CommunityV2/Community_banner_new.png" title="Poster Image"/>
        </picture>
      </PosterImage>
      <img src="/public/images/CommunityV2/community_mobile.png" title="Poster Image"/>
    </Section>
  )
  
  ratingsUI = () => (
    <RatingWrapper>
      <RatingsContainers>
        <img src={this.state.ratingImage[this.state.selectedTab]} alt={"rating"}/>
        <LazyImage div={"ratingStars"} src={ImageUrl + "/images/HomePageV2/ratingStars.png"} alt={"rating"} />
        <div className={"ratingDescription"}>{this.state.ratingData[this.state.selectedTab]}</div>
        <div className={"ratingBy"}>{this.state.ratingName[this.state.selectedTab]} <span>{this.state.ratingMain[this.state.selectedTab]}</span></div>
      </RatingsContainers>
    </RatingWrapper>
  );

  decreaseIndex = () => {
    const {selectedTab} = this.state;
    let temp = selectedTab-1;
    this.setState({selectedTab: temp})
  }

  increaseIndex = () => {
    const {selectedTab} = this.state;
    let temp = selectedTab+1;
    this.setState({selectedTab: temp})
  }

  handleTabs = (index) => {
    this.setState({selectedTab: index});
  }

  renderMenuList = () => {
    let TabArray = [
      {
        tabName: "50-200 employees",
        image: "/path/",
        text: "Healthcare data"
      },
      {
        tabName: "201-500 employees",
        image: "/path/",
        text: "civil data"
      },
      {
        tabName: "501-1,000 employees",
        image: "/path/",
        text: "Insurance data"
      }
    ]

    return(
      <TabsContainerTestimonial>
        <MenuListV2Main>
          {TabArray.map((data, index) => (
            <CustomListItemV2Main showTab={true} active={this.state.selectedTab === index} key={index} 
              onClick={() => this.handleTabs(index)}>
              <MenuLinks style={{textTransform: "lowercase"}}>{data.tabName}</MenuLinks>
            </CustomListItemV2Main>
          ))}
        </MenuListV2Main>
      </TabsContainerTestimonial>)
  };

  renderTabData=()=>(
    <TabData>
      <div className="responsiveWhyButton">
        <Button2 display={'block'} onClick={()=> this.props.history.push('/contact')}>BOOK A DEMO</Button2>
      </div>
      <div>
        <img src="/public/images/CommunityV2/Community_Image.png"/>
      </div>
      <div>
        <h1>Connect Peers That Share{this.state.mobileViewStatus ? '' : <br/>} Similar Interests And Goals</h1>
        <img src="/public/images/HomePageV2/blueDivider.png"/>
        <p>Uniting cross-functional teams allows for more<br/> 
          innovation employee development and growth.</p>
        <Button2 display={'block'} onClick={()=> this.props.history.push('/contact')}>REQUEST A DEMO</Button2>
      </div>
    </TabData>
  )

  handleResources = (resIndex, rowIndex) => {
    const {wellnessResources, image} = this.state;
    let array = wellnessResources;
    wellnessResources[resIndex].data.forEach((info, index) => {
      array[resIndex].data[index].status = info.status===1 && 0;
    })
    array[resIndex].data[rowIndex].status = 1;
    let images = image;
    images[resIndex] = wellnessResources[resIndex].data[rowIndex].image; 
    this.setState({wellnessResources: array, image: images});
  }

  multipleFeatures = () => (
    <ResourcesWrapper>{
      this.state.wellnessResources.length > 0 && this.state.wellnessResources.map((row,index)=>
        (<MainResources key={index} color={row.color}><h1>{row.heading}</h1>
          <LazyImage div={"line"} src={ImageUrl + "/images/HomePageV2/Rectanglewellness.png"}/>
          <Resources flexDirection={row.swapRow} col={row.color} align={index%2 === 0? 'flex-start': 'flex-end'}>
            <div>
              <div>
                <LazyImage src={ImageUrl +"/images/" + `${row.icon}`} alt={row.title}/>
                <div className={"title"}>{row.title}</div>
              </div>
              <div className="normal">
                {
                  row.data.map((info, rowindex) => (
                    <RowWrapper key={rowindex} selected={info.status} bgColor={row.bgColor} 
                      onClick={() => this.handleResources(index, rowindex)} customColor={info.status === 1 ? 1 : 0}>
                      <div className={"header"}>
                        <img src={`${imgPath}/${info.icon}`} alt={row.title}/>
                        <div>{info.header}</div>
                        <LazyImage div={"downArrow"} src={ImageUrl +"/images/WellnessPage/Vector (1).svg"} alt={"arrow"}/>
                      </div>
                      {(info.status === 1) && <div className={"expand"}>
                        {info.description}
                      </div>}
                    </RowWrapper>
                  ))
                }
              </div>
              <div className="responsive">
                {
                  row.data.map((info, rowindex) => (
                    <div>
                      <RowResponsiveWrapper key={rowindex} selected={info.status} bgColor={row.bgColor} onClick={() => this.handleResources(index, rowindex)} customColor={info.status === 1 ? 1 : 0}>
                        <div className={"header"}>
                          <img src={`${imgPath}/${info.icon}`} alt={row.title}/>
                          <div>{info.header}</div>
                          <LazyImage div={"downArrow"} src={ImageUrl +"/images/WellnessPage/Vector (1).svg"} alt={"arrow"}/>
                        </div>
                        {(info.status === 1) && <div className={"expand"}>
                          {info.description}
                        </div>}
                      </RowResponsiveWrapper>
                      {(info.status === 1) && <LazyImage div={"responsive-image"} src={ImageUrl + "/images/" + this.state.image[index]}/>}
                    </div>
                  ))
                }
              </div>
            </div>
            <LazyImage div={"image"} src={ImageUrl + "/images/" + this.state.image[index]}/>

          </Resources>
        </MainResources>))
    }</ResourcesWrapper>);
  coreFeatureSection = () => (
    <CoreFeatureSection>
      <div>
        <h1>Community Resources</h1>
        <LazyImage src={ImageUrl + "/images/HomePageV2/Rectanglewellness.png"}/>
      </div>
    </CoreFeatureSection>
  );
  
  wellBeingSection = () => (
    <WellBeing>
      <div>
        <div>
          <p>Are you ready to <br/>
          create a culture of <br/>
          wellbeing?</p>
          <ButtonGetStarted  display={'block'} 
            onClick={()=> this.props.history.push('/contact')}>GET STARTED
          </ButtonGetStarted>
        </div>
      </div>
      <LazyImage src={ImageUrl + "/images/HomePageV2/footerImage.png"}/>
    </WellBeing>
  )

  onEmailChange = (e) => {
    this.setState({email: e.target.value})
  }

  divider=()=>(
    <Divider>
      <img src={`${imgPath}/WellnessPage/blueDivider.png`} alt={"breaker"}/>
    </Divider>
  )
  onSubmit = (e) => {
    e.preventDefault();
    const { subscribeNewsLetter, history } = this.props;
    const { email } = this.state;
    let payload = {
      email
    };
    if(email.trim() !== '') {
      subscribeNewsLetter(history, payload);
      this.setState({
        email: ''
      })
    }
  };

  renderResponsiveTab = () => (
    <ResponsiveTestimonialTab>
      {this.state.selectedTab > 0 ? 
        this.state.selectedTab > 0 && <LazyImage div="leftArrow" onClick={() => this.decreaseIndex()} src={ImageUrl + "/images/HomePageV2/leftArrow.png"} />
        : <div className="leftArrow"></div>}
      <div>
        <p>
          {
            this.state.tabArray[this.state.selectedTab]
          }
        </p>
      </div>
      {this.state.selectedTab < 4 && 
          this.state.selectedTab < 4 && <LazyImage div="rightArrow" onClick={() => this.increaseIndex()} src={ImageUrl + "/images/HomePageV2/rightArrow.png"} /> }
    </ResponsiveTestimonialTab>
  );      
  render() {
    return(
      <WrapperContainer>
        {this.top()}
        {this.ratingsUI()}
        {this.renderResponsiveTab()}
        <PaddingContainer>{this.renderMenuList()}</PaddingContainer>
        {this.renderTabData()}
        <PaddingContainer>{this.coreFeatureSection()}</PaddingContainer>
        <PaddingContainer>{this.multipleFeatures()}</PaddingContainer>
        <PaddingContainer>{this.wellBeingSection()}</PaddingContainer>
      </WrapperContainer>
    );
  }
}
RecognitionPage.propTypes = {
  history: PropTypes.object,
  subscribeNewsLetter: PropTypes.func
};
const mapDispatchToProps = (dispatch) => ({
  subscribeNewsLetter: (history, payload) => dispatch(subscribeNewsLetter(history, payload))
});
export default connect(null, mapDispatchToProps)(RecognitionPage);
