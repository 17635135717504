import React, { Component } from 'react';
import Highcharts from 'highcharts';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Container = styled.div`
  width: 100%;
  height: 100%;

  .highcharts-container {
    width: 100% !important;
    height: 100% !important;

    > svg {
      width: 100%;
      height: 100%;

    }
  }
`;

class PieChart extends Component {

  componentDidMount() {
    const { pieValue, bgColor, title, containerId } = this.props;
    if(pieValue && title && containerId) {
      this.renderGraph(pieValue, bgColor, title, containerId);
    }
  }

  componentDidUpdate(prevProps) {
    if(this.props !== prevProps) {
      const { pieValue, bgColor, title, containerId } = this.props;
      this.renderGraph(pieValue, bgColor, title, containerId)
    }
  }

  renderGraph = (pieValue, bgColor, title, containerId) => new Highcharts.Chart({
    chart: {
      type: 'solidgauge',
      height: '70%',
      renderTo: containerId
    },

    title: {
      enabled: true,
      text: title,
      verticalAlign: 'bottom',
      align: 'center',
      style: {
        color: "#999999",
        textTransform: 'uppercase',
        fontSize: '14px',
        fontWeight: 'normal'
      }
    },
    subtitle: {
      text: pieValue+'%',
      align: 'center',
      verticalAlign: 'middle',
      y: 8,
      style: {
        color: '#999999',
        fontSize: '14px',
        fontWeight: 'bold'
      }
    },
    tooltip: {
      borderWidth: 0,
      backgroundColor: 'none',
      text: null,
      style: {
        display: "none"
      }
    },

    pane: {
      startAngle: 0,
      endAngle: 360,
      background: [{ // Track for Move
        outerRadius: '90%',
        innerRadius: '55%',
        backgroundColor: '#dae6c2',
        borderWidth: 0
      }]
    },

    yAxis: {
      min: 0,
      max: 100,
      lineWidth: 0,
      tickPositions: []
    },

    plotOptions: {
      solidgauge: {
        dataLabels: {
          enabled: false
        },
        linecap: 'square',
        stickyTracking: true,
        rounded: false,
        animation: false
      }
    },

    series: [{
      name: null,
      data: [{
        color: bgColor,
        radius: '90%',
        innerRadius: '55%',
        y: pieValue
      }]
    }]
  });

  render() {
    const { containerId } = this.props;
    return (
      <Container id={containerId} />
    );
  }
}

PieChart.propTypes = {
  pieValue: PropTypes.number,
  bgColor: PropTypes.string,
  title: PropTypes.string,
  containerId: PropTypes.string
};

export default PieChart;